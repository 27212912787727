<template>
  <section>
    <v-container class="container-custom page">
      <v-row>
        <v-col md="12" v-if="loading">Loading...</v-col>
        <v-col md="12" v-if="!loading">
          <div v-html="page.description"></div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Page",
  data: () => ({
    loading: true,
    page: undefined,
  }),
  computed: {
    ...mapGetters(["allPage"]),
  },
  methods: {
    ...mapActions(["getSingleFrontendPage"]),
  },
  async created() {
    await this.getSingleFrontendPage(this.$route.params.slug);
    this.page = this.allPage.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

</style>