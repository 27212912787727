<template>
  <section>
    <v-container class="container-custom page py-10">
      <v-row>
        <v-col md="8" v-if="loading" class="pt-10">
          <Loading />
        </v-col>
        <v-col md="8" v-if="!loading">
          <SingleSilderBlog :images="blog.images" />
          <div v-html="blog.description"></div>
        </v-col>
        <v-col md="4">
          <div class="siderBox">
            <header>
              <strong>Latest Blogs</strong>
              <router-link to="/"
                >View All
                <v-icon> mdi-chevron-double-right </v-icon></router-link
              >
            </header>
            <div v-if="loading">
              <Loading/>
            </div>
            <div v-if="!loading">
              <BlogListSide
                v-for="(data, index) in blogs"
                :key="index + 'blog'"
                :item="data"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SingleSilderBlog from "@/components/SingleSilderBlog";
import { mapActions, mapGetters } from "vuex";
import BlogListSide from "@/components/BlogListSide";
import Loading from "@/components/Loading";
export default {
  name: "SingleBlog",
  data: () => ({
    loading: true,
    blog: undefined,
    blogs:[]
  }),
  components: {
    SingleSilderBlog,
    BlogListSide,
    Loading
  },
  computed: {
    ...mapGetters(["allBlog","allBlogs"]),
  },
  methods: {
    ...mapActions(["getFrontendSingleBlog","getFrontendBlogs"]),
  },
  async created() {
    await this.getFrontendSingleBlog(this.$route.params.id);
    await this.getFrontendBlogs({limit:5,page:1});
    this.blog = this.allBlog.results;
    this.blogs = this.allBlogs.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.viewMore {
  .v-btn {
    text-transform: capitalize;
    min-height: 60px !important;
    width: 200px;
  }
}
.compareBox {
  cursor: pointer;
}
.primary {
  border: 1px solid blueviolet;
  min-height: 50px;
  width: 220px;
  background: transparent !important;
  box-shadow: none !important;
  color: blueviolet !important;
}
</style>