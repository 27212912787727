<template>
  <section class="box">
    <div class="productCardTop">
      <router-link :to="`/blog/${item.slug}`">
        <img v-if="item.images.length > 0" :src="item.images[0].media" alt="" />
      </router-link>
    </div>
    <div class="productCardBody">
      <router-link :to="`/blog/${item.slug}`">
        <h4>{{item.blogTitle}}</h4>
      </router-link>
      <p>{{item.category}}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogListSide",
  props:['item']
};
</script>

<style lang="scss" scoped>
.box {
  border: 2px solid #ededed;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.borderBox {
  border-bottom: 1px solid #ededed;
  display: block;
}
img {
  width: 100%;
  object-fit: contain;
  height: 100px;
}
a {
  text-decoration: none;
  color: #000!important;
}
.productCardBody {
  padding: 15px;
  margin-top: 10px;
  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }

}


</style>